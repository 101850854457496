<template>
<v-app :style="{ background: '#efefef' }">
    <div class="container">
        <h2>
            Diagnóstico
        </h2>
        <span>
            Ingrese el código CIE 10 y luego presione en Guardar
        </span>
        <div class="d-flex cie-10 mb-5">
            <input v-model="cie10_1.cod" type="text" disabled>
            <v-btn @click="showModalCie10('cie10_1')" elevation="2" color="green">
                BUSCAR
            </v-btn>
        </div>
        {{ cie10_1.diagnostico }}
        <div class="d-flex cie-10 mb-5">
            <input v-model="cie10_2.cod" type="text" disabled>
            <v-btn @click="showModalCie10('cie10_2')" elevation="2" color="green">
                BUSCAR
            </v-btn>
        </div>
        {{ cie10_2.diagnostico }}
        <h2 class="mt-10 mb-5">
            Tratamiento:
        </h2>
        <v-btn @click="dialogProcedimiento = true" block elevation="2" color="#d6d8d7">
            PROCEDIMIENTO
        </v-btn>
        <hr class="mt-1 mb-5">
        <v-checkbox @change="update(medicamentoControl, 'medicamentoControl')" label="Medicamentos de control" v-model="medicamentoControl" hide-details color="success">
        </v-checkbox>
        <v-btn class="my-5" :disabled="!medicamentoControl" @click="dialogMedicamentos = true" block elevation="2" color="#d6d8d7">
            MEDICAMENTOS DE CONTROL
        </v-btn>
        <p>
            Análisis y tratamiento:
        </p>
        <input @input="update($event,'analisisYTratamiento')" type="text" class="input-medicamento mb-5" v-model="analisisYTratamiento">
        <h2>
            Recomendaciones
        </h2>
        <input @input="update($event,'recomendaciones')" type="text" class="input-medicamento mb-10" v-model="recomendaciones">
        <h2>
            Firmas:
        </h2>
        <p>
            Elija la firma que desea diligenciar, si se presenta traslado a otra entidad, marque la casilla e ingrese la firma.
        </p>
        <v-btn class="my-5" @click="dialogFirma = true; fileName='firma_consulta_medica.png'" block elevation="2" color="#d6d8d7">
            FIRMA CONSULTA MÉDICA
        </v-btn>
        <v-btn block elevation="2" color="#4451b4" style="color: white;" class="mb-10" @click="dialogCalificarConsulta = true">
            CALIFICAR CONSULTA
        </v-btn>
        <div class="d-flex flex-wrap justify-space-between" style="gap:2%;" >
            <v-btn @click="dialogFirma = true; fileName='firma_insumos.png'" elevation="2" color="#d6d8d7" class="mb-5" style="width:49%;">
                FIRMA INSUMOS
            </v-btn>
            <v-btn @click="dialogFirma = true; fileName='firma_consentimiento.png'" elevation="2" color="#d6d8d7" class="mb-5" style="width:49%;">
                FIR. CONSENTIMIENTO
            </v-btn>
        </div>
        <div class="d-flex flex-wrap justify-space-between" style="gap:2%;" >
            <v-btn disabled @click="dialogFirma = true; fileName='firma_entidad.png'" elevation="2" color="#d6d8d7" class="mb-5" style="width:49%;">
                FIRMA ENTIDAD
            </v-btn>
            <v-btn @click="dialogFirma = true; fileName='firma_desistimiento.png'" elevation="2" color="#d6d8d7" class="mb-5" style="width:49%;">
                FIR. DESISTIMIENTO
            </v-btn>
        </div>
        <v-btn class="mb-5" disabled elevation="2" color="#d6d8d7" block>
            INDICADOR QUIMICO
        </v-btn>
        <v-btn class="mb-5" elevation="2" color="#d6d8d7" block>
            ENTREGA SALBUTAMOL
        </v-btn>
        <h2>
            Código Valoración:
        </h2>
        <v-radio-group @change="update(codigoValoracion, 'codigoValoracion')" row v-model="codigoValoracion">
            <v-radio color="success" key="rojo" label="Rojo" value="R"></v-radio>
            <v-radio color="success" key="amarillo" label="Amarillo" value="A"></v-radio>
            <v-radio color="success" key="verde" label="Verde" value="V"></v-radio>
            <v-radio color="success" key="azul" label="Azul" value="P"></v-radio>
        </v-radio-group>
        <h2>
            Reconsulta
        </h2>
        <v-radio-group @change="update(reconsulta, 'reconsulta')" row v-model="reconsulta">
            <v-radio color="success" key="si" label="Si" :value="true"></v-radio>
            <v-radio color="success" key="no" label="No" :value="false"></v-radio>
        </v-radio-group>
        <p>
          Última consulta: {{ currentPatientInfo.ult_diag ?? '' }} ({{ currentPatientInfo.ult_fecha ?? '' }} {{ currentPatientInfo.ult_hora ?? '' }})
          <br> 
          Traslado: {{ calcUltimaConsulta(currentPatientInfo.ult_traslado) }}
        </p>
        <h2>
            Formación al usuario:
        </h2>
        <v-radio-group @change="update(formacion, 'formacion')" row v-model="formacion">
            <v-radio color="success" key="si" label="Si" :value="true"></v-radio>
            <v-radio color="success" key="no" label="No" :value="false"></v-radio>
        </v-radio-group>
        <p v-if="formacion">
            SE REALIZA EDUCACIÓN AL PACIENTE Y/O FAMILIAR EN
        </p>
        <v-autocomplete v-if="formacion" @change="update(educacion, 'educacion')" v-model="educacion" :items="educacionItems" outlined dense></v-autocomplete>
        <h2>
            Novedades:
        </h2>
        <v-radio-group @change="update(novedadesRadio, 'novedadesRadio')" row v-model="novedadesRadio">
            <v-radio color="success" key="si" label="Si" :value="true"></v-radio>
            <v-radio color="success" key="no" label="No" :value="false"></v-radio>
        </v-radio-group>
        <v-autocomplete v-if="novedadesRadio" @change="update(novedadesSelect, 'novedadesSelect')" v-model="novedadesSelect" :items="novedadesItems" outlined dense></v-autocomplete>
        <input placeholder="Novedades..." @input="update($event,'novedadesInput')" type="text" class="input-medicamento mb-5" v-model="novedadesInput">
        <v-btn block elevation="2" color="green" style="color: white;" class="mb-3 mt-10">
            FINALIZAR CONSULTA
        </v-btn>
        <v-btn block elevation="2" color="#d6d8d7" @click="$router.push({name: 'DatosClinicos'})">
            ATRÁS
        </v-btn>
    </div>
    <v-dialog v-model="dialogBuscarCie10" width="500">
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                Búsqueda CIE10:
            </v-card-title>

            <v-card-text>
                <p class="my-3">
                    Busque cualquier diagnóstico por alguna palabra, o por algún código CIE10
                </p>
                <v-text-field v-model="buscar" @input="searchCie10"></v-text-field>
                <v-autocomplete v-model="cie10_temp.cod" :items="cie10" outlined dense item-text="diagnostico" item-value="cod" @change="asignarDiagnostico"></v-autocomplete>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn elevation="2" color="#4451b4" style="color: white;" @click="asignarCie10(tempCie10Variable)">
                    Aceptar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProcedimiento" width="500" persistent>
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                Procedimiento
            </v-card-title>

            <v-card-text>
                <p>
                    Administración del medicamento vía:
                </p>
                <v-checkbox label="Oral" value="oral" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Sublingual" value="sublingual" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Intravenosa" value="intravenosa" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Intramuscular" value="intramuscular" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Subcutánea" value="subcutanea" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Nasal" value="nasal" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Administración de oxígeno" value="administración de oxigeno" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <hr class="my-5">
                <p>
                    Procedimientos:
                </p>
                <v-checkbox label="Enema Rectal" value="enema rectal" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Nebulización" value="nebulizacion" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Electrocardiograma" value="electrocardiograma" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Inmovilización" value="inmovilizacion" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Cricotiroidotomía" value="cricotiroidotomia" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Parto de Emergencia" value="parto de emergencia" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Lavado de Herida" value="Lavado de Herida" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Sutura" value="sutura" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Retiro de Puntos" value="retiro de puntos" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Aspiración de Secreciones" value="aspiracion de secreciones" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Cateterismo Vesical" value="cateterismo vesical" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Glucometría" value="glucometria" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Sonda Nasogástrica" value="sonda nasogastrica" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Reanimación" value="reanimacion" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox label="Ventilación Mecánica" value="ventilación mecanica" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
                <v-checkbox class="my-5" label="Uso instrumental" value="uso instrumental" v-model="procedimientosTemp" hide-details color="success">
                </v-checkbox>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="asignarProcedimientos()">
                    Aceptar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogMedicamentos" width="500" persistent>
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                Medicamentos de control
            </v-card-title>

            <v-card-text>
                <p>Alprazolam 0.5 mg:</p>
                <input v-model="medicamentosControlTemp.alprazolam" type="text" class="input-medicamento">
                <p>Morfina 10mg/ML:</p>
                <input v-model="medicamentosControlTemp.morfina" type="text" class="input-medicamento">
                <p>Diazepam:</p>
                <input v-model="medicamentosControlTemp.diazepam" type="text" class="input-medicamento">
                <p>Meperidina:</p>
                <input v-model="medicamentosControlTemp.meperidina" type="text" class="input-medicamento">
                <p>Midazolam 5mg/5ML:</p>
                <input v-model="medicamentosControlTemp.midazolam1" type="text" class="input-medicamento">
                <p>Midazolam 15mg/3ML:</p>
                <input v-model="medicamentosControlTemp.midazolam2" type="text" class="input-medicamento">
                <p>Clonazepam 2.5/30ML (gotas):</p>
                <input v-model="medicamentosControlTemp.clonazepam1" type="text" class="input-medicamento">
                <p>Clonazepam 2.5/30ML (frasco):</p>
                <input v-model="medicamentosControlTemp.clonazepam2" type="text" class="input-medicamento">
                <p>Olazapina 10mg - NO M.C.E:</p>
                <input v-model="medicamentosControlTemp.olazapina" type="text" class="input-medicamento">
                <p>Fenobarbital:</p>
                <input v-model="medicamentosControlTemp.fenobarbital" type="text" class="input-medicamento">
                <p>Fentanyl:</p>
                <input v-model="medicamentosControlTemp.fentanyl" type="text" class="input-medicamento">
                <p>Haloperidol 5mg - No M.C.E:</p>
                <input v-model="medicamentosControlTemp.haloperidol" type="text" class="input-medicamento">
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="cerrarMedicamentosControl">
                    CANCELAR
                </v-btn>
                <v-btn color="success" text @click="asignarMedicamentosControl">
                    Aceptar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogFirma" width="500">
        <v-card>
            <firma @image="saveSignature(fileName, $event)"></firma>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCalificarConsulta">
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                Calificar Consulta
            </v-card-title>

            <v-card-text>
                <p class="my-3">
                    Quien realiza la calificación?
                </p>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn @click="dialogCalificarConsulta = false; dialogCalificar = true;" text color="success" style="font-size: 0.8rem;">
                    RESPONSABLE
                </v-btn>
                <v-btn @click="dialogCalificarConsulta = false;" text color="success" style="font-size: 0.8rem;">
                    RECHAZAR
                </v-btn>
                <v-btn @click="dialogCalificarConsulta = false; dialogCalificar = true;" text color="success" style="font-size: 0.8rem;">
                    PACIENTE
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCalificar" width="500">
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                Calificar Consulta
            </v-card-title>

            <v-card-text>
                <p class="my-3">
                    Califique de 1 a 5 los siguientes aspectos, Tenga en cuenta que: (1) Muy Baja (2) Baja (3) Aceptable (4) Alta y (5) Muy Alta.
                </p>
                <p>
                    1. Atención del medico
                </p>
                <v-radio-group row v-model="radioAtencionMedico">
                    <v-radio v-for="n in 5" color="success" :key="n" :label="`${n}`" :value="n"></v-radio>
                </v-radio-group>
                <p>
                    2. Atención del auxiliar de enfermería
                </p>
                <v-radio-group row v-model="radioAtencionEnfermera">
                    <v-radio v-for="n in 5" color="success" :key="n" :label="`${n}`" :value="n"></v-radio>
                </v-radio-group>
                <p>
                    3. Atención al llamar a solicitar medico
                </p>
                <v-radio-group row v-model="radioAtencionLlamada">
                    <v-radio v-for="n in 5" color="success" :key="n" :label="`${n}`" :value="n"></v-radio>
                </v-radio-group>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="dialogCalificar = false;" text color="success" v-if="radioAtencionMedico && radioAtencionEnfermera && radioAtencionLlamada">
                    GUARDAR
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
import {
    mapState
} from "vuex";
import currentServiceModule from "@/modules/currentService";
import Firma from "@/components/consulta/Firma.vue";
import sweetAlert from "@/modules/sweetAlert";
export default {
    name: "DatosPaciente",
    computed: mapState({
        currentService: (state) => state.currentService,
        historiasClinicas: (state) => state.historiasClinicas,
        currentPatientInfo: (state) => state.currentPatientInfo,
    }),
    components: {
        Firma
    },
    data: () => ({
        cie10_1: {},
        cie10_2: {},
        dialogBuscarCie10: false,
        cie10_temp: {},
        buscar: '',
        cie10: [],
        updateDebounce: null,
        timeout: null,
        timeTimeout: 1000,
        tempCie10Variable: '',
        dialogProcedimiento: false,
        procedimientosTemp: [],
        procedimientos: [],
        medicamentoControl: false,
        medicamentosControl: {
            alprazolam: '',
            morfina: '',
            diazepam: '',
            meperidina: '',
            midazolam1: '',
            midazolam2: '',
            clonazepam1: '',
            clonazepam2: '',
            olazapina: '',
            fenobarbital: '',
            fentanyl: '',
            haloperidol: '',
        },
        medicamentosControlTemp: {},
        dialogMedicamentos: false,
        analisisYTratamiento: '',
        recomendaciones: '',
        fileName: '',
        dialogFirma: false,
        dialogCalificarConsulta: false,
        dialogCalificar: false,
        radioAtencionMedico: null,
        radioAtencionEnfermera: null,
        radioAtencionLlamada: null,
        codigoValoracion: null,
        reconsulta: null,
        formacion: null,
        educacion: null,
        educacionItems: [
            'Pautas para una atención Segura',
            'Clasificación TRIAGE',
            'Recomendaciones en EDA',
            'Recomendaciones para IRA',
            'Pautas de hipertensión Arterial',
            'Recomendación ante la Fiebre',
            'Recomendaciones para pacientes con asma o enfermedades respiratorias crónicas.'
        ],
        novedadesRadio: false,
        novedadesInput: '',
        novedadesSelect: null,
        novedadesItems: [
            'Paciente policonsultante',
            'Paciente que usa inadecuadamente el servicio',
            'Paciente difícil e irrespetuoso con el equipo de atención',
            'Paciente que requiere otro tipo de servicio en su EPS',
            'Otras'
        ]
    }),
    created() {
        const serviceData = this.historiasClinicas[this.currentService];
        this.cie10_1 = serviceData.cie10_1 ?? {};
        this.cie10_2 = serviceData.cie10_2 ?? {};
        this.procedimientos = serviceData.procedimientos ?? [];
        this.codigoValoracion = serviceData.codigoValoracion ?? null;
        this.procedimientosTemp = this.procedimientos;
        this.medicamentoControl = serviceData.medicamentoControl ?? false;
        this.novedadesRadio = serviceData.novedadesRadio ?? false;
        this.reconsulta = serviceData.reconsulta ?? null;
        this.formacion = serviceData.formacion ?? null;
        this.educacion = serviceData.educacion ?? null;
        this.novedadesSelect = serviceData.novedadesSelect ?? null;
        this.medicamentosControl = serviceData.medicamentosControl ?? {
            alprazolam: '',
            morfina: '',
            diazepam: '',
            meperidina: '',
            midazolam1: '',
            midazolam2: '',
            clonazepam1: '',
            clonazepam2: '',
            olazapina: '',
            fenobarbital: '',
            fentanyl: '',
            haloperidol: '',
        }
        this.medicamentosControlTemp = this.medicamentosControl;
        this.analisisYTratamiento = serviceData.analisisYTratamiento ?? '';
        this.novedadesInput = serviceData.novedadesInput ?? '';
        this.recomendaciones = serviceData.recomendaciones ?? '';

        this.updateDebounce = this.debounce((text) => {
            console.log(text);
            // Consulta
            currentServiceModule.getDiagnosticos(text).then((response) => {
                console.log(response);
                this.cie10 = response;
            }).catch((error) => {
                console.log(error);
            })
        });
    },
    methods: {
        update(event, key) {
            this.$store.commit("setKeyToHistory", {
                consecutivo: this.currentService,
                key,
                value: event.target ? event.target.value : event,
            });
        },
        saveSignature(filename, imageBase64) {
            currentServiceModule.saveImage({
                documento: this.currentPatientInfo.bendoc,
                consecutivo: this.currentService,
                imagen: imageBase64,
                nombre: filename
            }).then((response) => {
                console.log(response);
                sweetAlert.fire({
                    title: "Firma Registrada!",
                    icon: "success",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    allowEnterKey: false,
                    timer: 2000,
                    position: "center",
                });
            }).catch((error) => {
                console.log(error);
                sweetAlert.fire({
                    title: "Error al subir la firma",
                    icon: "error",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    allowEnterKey: false,
                    timer: 2000,
                    position: "center",
                });
            }).finally(() => {
                this.dialogFirma = false;
            })
        },
        showModalCie10(variable) {
            this.tempCie10Variable = variable;
            this.cie10_temp = JSON.parse(JSON.stringify(this[variable]));
            this.dialogBuscarCie10 = true;
        },
        searchCie10(text) {
            this.updateDebounce(text);
        },
        debounce(cb, delay = this.timeTimeout) {
            return (...args) => {
                clearTimeout(this.timeout);
                if (this.buscar.length > 2) {
                    this.timeout = setTimeout(() => {
                        cb(...args);
                    }, delay);
                }
            };
        },
        asignarCie10(variable) {
            this[variable] = JSON.parse(JSON.stringify(this.cie10_temp));
            this.update(this[variable], variable);
            this.cie10_temp = {};
            this.tempCie10Variable = '';
            this.dialogBuscarCie10 = false;
        },
        asignarDiagnostico() {
            this.cie10.forEach((element) => {
                if (element.cod === this.cie10_temp.cod) {
                    console.log('hola');
                    this.cie10_temp.diagnostico = element.diagnostico
                }
            })
        },
        asignarProcedimientos() {
            this.dialogProcedimiento = false;
            this.procedimientos = this.procedimientosTemp;
            this.update(this.procedimientos, 'procedimientos');
        },
        cerrarMedicamentosControl() {
            this.medicamentosControlTemp = JSON.parse(JSON.stringify(this.medicamentosControl))
            this.dialogMedicamentos = false;
        },
        asignarMedicamentosControl() {
            this.medicamentosControl = JSON.parse(JSON.stringify(this.medicamentosControlTemp))
            this.update(this.medicamentosControl, 'medicamentosControl');
            this.dialogMedicamentos = false;
        },
        calcUltimaConsulta(consulta) {
          console.log(consulta);
          if(!consulta) { return 'No Aplica'; }
          const newConsulta = Number(consulta);
          switch (newConsulta) {
            case 30:
              return 'Si'
            case 40:
              return 'Si'
            case 10:
              return 'No'
            case 20:
              return 'No'
            case 41:
              return 'No'
            default:
              return 'No Aplica'
          }
        }
    }
};
</script>

<style lang="scss">
.container {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;

    .cie-10 {
        gap: 1rem;
        align-items: center;

        input {
            height: fit-content;
            width: -webkit-fill-available;
            border-bottom: black solid 1px;
        }

        button {
            color: white;
        }

    }
}

.input-medicamento {
    border-bottom: black solid 1px;
    width: 100%;
}
</style>
