<template>
<div class="container">
    <div class="container">
        <VueSignaturePad class="signature" width="100%" height="200px" ref="signaturePad" :options="options" />
    </div>
    <div class="buttons">
        <v-btn
          elevation="2"
          color="#4451b4"
          style="color: white;"
          class="mb-10"
          @click="undo"
        >
        Borrar
        </v-btn>
        <v-btn
          elevation="2"
          color="#4451b4"
          style="color: white;"
          class="mb-10"
          @click="save"
        >
        Guardar
        </v-btn>
    </div>
</div>
</template>

<script>
// import sweetAlert from "@/modules/sweetAlert";
export default {
    name: "DatosPaciente",
    data: () => ({
        penColor: "black",
        options: {
            penColor: "black"
        }
    }),

    methods: {
        undo() {
            this.$refs.signaturePad.clearSignature();
        },
        save() {
            const {
                isEmpty,
                data
            } = this.$refs.signaturePad.saveSignature();

            if(isEmpty) {
                return;
            }
            console.log(data);
            this.$emit('image', data);
            this.undo();
        },
    }
};
</script>

<style lang="scss" scoped>
.signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.container {
  width: "100%";
  padding: 8px 16px;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}
</style>

